<template>
  <main :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="exchange-service-common">
      <!-- TITLE SERVICE -->
      <section class="intro-service container">
        <!-- INFO SERVICE -->
        <h3 class="sp title-name">タリーズデジタルギフト</h3>
        <div class="title-left">
          <img src="../../../assets/images/tully.png" alt="logo">
          <div class="info">
            <h3 class="pc title-name">タリーズデジタルギフト</h3>
            <p>タリーズデジタルギフトは、日本国内のタリーズコーヒー店舗にて販売している商品のお買物にご利用いただけます。(但し、一部店舗を除きます)<br>
              ※取り扱いのない店舗は下記URLよりご確認をお願いします<br>
              <a class="highlight" href="https://www.tullys.co.jp/cpn/tullyscard/list.html" target="_blank">https://www.tullys.co.jp/cpn/tullyscard/list.html</a>
            </p>
          </div>
        </div>

        <!-- RULES SERVICE -->
        <ul class="rules-service">
          <li>
            <span>交換日数</span>
            <p><b>{{ pointExchangeService.exchange_date }}</b></p>
          </li>
          <li>
            <span>交換手数料</span>
            <p><b>無料</b></p>
          </li>
          <li>
            <span>交換レート</span>
            <strong class="unit-gpoint"><div>1pt →</div><span>1円相当</span></strong>
          </li>
          <li>
            <span>最低交換ポイント</span>
            <p class="point">500pt</p>
          </li>
        </ul>
      </section>

      <!-- NOTE SERVICE -->
      <section class="note-service d-flex">
        <div class="container d-inline-block">
          <p>【利用方法】<br>
            1.タリーズコーヒー店頭で「タリーズデジタルギフト」での支払いをお伝えください<br>
            2. 画面を明るくし「バーコードを表示」ボタンを押して、店頭でご提示ください<br><br>
            【注意事項】<br>
            この商品の有効期限は6ヶ月です。<br>
            画面を画像で保存されたスクリーンショットや出力紙のご提示では、ご利用をお断りいたします。<br>
            バーコードを表示する際は、画面の明るさを最大にしてください。<br>
            保護シールを貼っている場合、読めない場合がございます。<br>
            バーコードが読み取れない場合、お使いのスマートフォンの文字サイズを標準にすることで改善される場合がございます。<br>
            残高及び有効期限は、本ページでご確認ください。<br>
            本デジタルギフトには有効期限があります。有効期限をご確認の上、お早めにご利用ください。<br>
            有効期限内にご利用がない場合、残高の有無に関わらず無効となり、残高の払い戻しはいたしかねます。<br>
            本デジタルギフトにはチャージできません。<br>
            利用後の残高は次回の決済時もご利用できます。<br>
            不足分の金額は、タリーズカード及びタリーズデジタルギフト以外の手段でお支払い可能です。<br>
            店頭でデジタルギフトをご利用いただいた後、デジタルギフト画面上の残高は表示した際のままになります。再度、URLからアクセスいただくと、最新の残高に更新されます。<br>
            バーコードが読み取れない場合、お使いのスマートフォンの文字サイズを標準にすることで改善される場合がございます。<br><br>
            ※取り扱いのない店舗は下記URLよりご確認をお願いします<br>
            <a class="highlight" href="https://www.tullys.co.jp/cpn/tullyscard/list.html" target="_blank">https://www.tullys.co.jp/cpn/tullyscard/list.html</a>
          </p>
        </div>
      </section>

      <!-- EXCHANGE POINT -->
      <div class="control-exchange">
        <!-- BTN EXCHANGE POINT -->
        <button @click="handleBeforeExchangePoint"
                v-if="profile && token"
                class="btn-exchange-point">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを交換する
        </button>

        <!-- BTN redirect to login -->
        <button v-else @click="openPageNative('Login')"
                class="btn-exchange-point btn-redirect">
          すでに会員の方はこちらからログイン
        </button>

        <!-- BTN BACK -->
        <div class="link-back">
          <router-link class="btn-back"
                       to="/exchange-point">
            <span>
               <ChevronLeftIcon size="20" />ポイント交換先一覧へ戻る
            </span>
          </router-link>
        </div>
      </div>
    </div>

    <!-- MODAL INPUT EXCHANGE POINT -->
    <Modal @close-modal="flagModalInput = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalInput">
      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="validateBeforeSubmit()"
        slot="body"
        class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;タリーズデジタルギフト </h3>
        <p class="sub-title">タリーズデジタルギフトへの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange">
          <span>交換</span>
          <InputField v-model="pointExchange"
                      field="交換ポイント"
                      type="text"
                      class="point-type"
                      :max-length="15"
                      @input="handleNumber($event)"
                      @focusout="handleNumberFocusOut()"
                      @focusin="handleNumberFocusIn($event)"
                      :rules="{
                        required: true,
                        number_exchange: true,
                        compare_point: {
                          first: pointExchange && parseInt(pointExchange.replace(/,/g, '')),
                          second: user_point.total_point_amount
                        },
                        range_point: true
                      }"
                      vid="point"/>
          <span class="pc">pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange point-line">
          <span>付与</span>
          <InputField v-model="pointExchange"
                      class="point-type"
                      :disabled="true"/>
          <span class="pc">円相当</span>
        </div>

        <!-- NOTE -->
        <p class="note">交換するポイントを入力して交換ポイントを確定するボタンをクリックしてください。</p>
      </ValidationObserver>

      <div class="btn-footer"
           slot="footer">
        <button @click.prevent="validateBeforeSubmit">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換ポイントを確定する
        </button>
      </div>
    </Modal>

    <!-- MODAL CONFIRM EXCHANGE POINT -->
    <Modal @close-modal="flagModalConfirm = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalConfirm">
      <div slot="body"
           class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;タリーズデジタルギフト </h3>
        <p class="sub-title">タリーズデジタルギフトの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange confirm"
             :class="{ 'point-input' : !flagModalConfirm }">
          <span>交換</span>
          <strong class="point">{{ pointExchange }}</strong>
          <span>pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img v-if="flagModalConfirm" class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange confirm">
          <span :class="{ 'orange': !flagModalConfirm }">付与</span>
          <strong>{{ pointExchange }}</strong>
          <span :class="{ 'orange': !flagModalConfirm }">円相当</span>
        </div>

        <!-- NOTE MODAL CONFIRM -->
        <p class="note text-center">交換申請するボタンをクリックするとポイント交換が完了となります。</p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button @click="handleExchangePoint">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換申請する
        </button>
      </div>
    </Modal>

    <!-- MODAL COMPLETE EXCHANGE POINT -->
    <Modal @close-modal="handleCloseModal"
           class="modal-exchange-point modal-center"
           v-if="flagModalComplete">
      <div class="content-body"
           slot="body">
        <h3>ポイント交換先<br class="sp">&#12288;タリーズデジタルギフト </h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ pointExchange }}<span class="color-orange sp" v-html="'円相当'"></span></strong>
            <time
              class="time-request"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ new Date() | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange"
                v-html="'円相当'"></span>
        </div>

        <p
          class="text-instruct"
          v-if="isExchangeAutoSuccess"
        >
          「タリーズデジタルギフトを表示」ボタンからギフトをお受け取りください
        </p>

        <p
          class="text-instruct manual"
          v-if="!isExchangeAutoSuccess"
        >
          ポイント交換の申請を受け付けました。<br>承認されましたら、マイページのポイント交換履歴から、ギフトの受け取りができるようになります。
        </p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button
          class="btn-common"
          @click.prevent="openUrl(dataComplete.url)"
          v-if="isExchangeAutoSuccess"
        >
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">タリーズデジタルギフトを表示
        </button>
      </div>
    </Modal>

    <!-- MODAL NOTIFY -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification"/>

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="handleReturn"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL CAUTION INFO -->
      <Modal @close-modal="flagModalRedirect = false"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
          <div slot="body">
            <p>ポイント交換前に必要事項をご入力ください。</p>

            <button
              @click.prevent="handelRedirectUpdateInfo"
              v-html="'会員情報入力'"
              class="btn-into-modal"
            />
          </div>
    </Modal>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"/>

    <!-- MODAL NOTIFY LINE_PAY AUTHENTICATION-->
    <!-- <ModalLinePayNotification
      :turnOffLinePayNotification="turnOffLinePayNotification"
      :isLinePayValidationError="isLinePayValidationError"
      :linePayValidationErrorMessage="linePayValidationErrorMessage"/> -->
  </main>
</template>

<script>
import { ChevronLeftIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'
import SNS from '@/mixins/sns.mixin'
import DigitalGift from '@/mixins/digitalGift.mixin'
import store from '@/store'
import Modal from '@/components/advertising-detail/Modal'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import InputField from '@/components/form/InputField'
import { EXCHANGE_POINT_SERVICE } from '@/enum/exchange-service'

export default {
  name: 'Index',

  mixins: [Common, SNS, DigitalGift],

  components: {
    ModalVerifyAuthenMobile,
    InputField,
    Modal,
    ChevronLeftIcon
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})

      if (this.token) {
        store.dispatch('auth/userPoint')
      }
    }
  },

  computed: {
    pointExchangePayload () {
      return {
        service_id: EXCHANGE_POINT_SERVICE.tully.id,
        point: this.pointExchangeMapping()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/exchange-service.scss';
.highlight {
  color: #C66C44;
}
</style>
